import { onUnmounted, onMounted, ref } from 'vue'

export function useGeolocation() {
  const coords = ref({ latitude: 0, longitude: 0 })
  const isSupported = 'navigator' in window && 'geolocation' in navigator

  let watcher = null
  onMounted(() => {
    if (isSupported)
      watcher = navigator.geolocation.getCurrentPosition(
        position => {
          coords.value = position.coords
        },
        () => {
          coords.value = {  latitude: 34.048899694445915,  longitude: -118.24806001262037, zoom: 11 }
        },
        { timeout: 10000 },
      )
  })
  onUnmounted(() => {
    if (watcher) navigator.geolocation.clearWatch(watcher)
  })

  return { coords, isSupported }
}
