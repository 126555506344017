<template>
  <div class="introduction">
    <div class="introduction__wrapper">
      <div style="width: fit-content; position: relative; left: -14px">
    <img class="introduction__img" src="../assets/img/boxes.png" />        
        <div class="introduction__rule">
          <p class="introduction__rule-text">
            Get a $10 gift card with code SAVE10
          </p>
        </div>
      </div>
    </div>
    <div class="introduction__wrapper-desktop">
      <div class="introduction__container-desktop">
        <div class="introduction__container">
          <h2 class="introduction__title">
            Same-day delivery <span>in&nbsp;Los&nbsp;Angeles</span>
          </h2>
          <p class="introduction__inform">
            We provide reliable and fast delivery of different kinds of goods, from food to flowers and gifts. 
          </p>
          <p class="introduction__inform">
          Power up your order with a $10 gift card <strong class="giftcard">SAVE10</strong>
          </p>
          <p class="introduction__title">
            Set your price and get it delivered nice!
          </p>
          
        </div>

        <div class="introduction__form">
          <AddressInput
            :arrival="form.route.departure_point"
            placeholder="Sender address"
            @set-address="
              (params) => {
                form.route.departure_point.address = params.address;
                form.route.departure_point.point.lat = params.lat;
                form.route.departure_point.point.lon = params.lon;
              }
            "
            @click="formStart1Handler"
          />
          <AddressInput
            :arrival="form.route.arrival_points[0]"
            placeholder="Recipient address"
            @set-address="
              (params) => {
                form.route.arrival_points[0].address = params.address;
                form.route.arrival_points[0].point.lat = params.lat;
                form.route.arrival_points[0].point.lon = params.lon;
                routeCalcHandler();
              }
            "
            @click="formStart1Handler"
          />
          <div class="shipping__wrapper"><br><br>
            <h4 class="shipping__title shipping__title-total">
             {{ `&nbsp; Recommended price is $ ${price ? price : " "}` }}
            </h4>
            <br>
            <p class="introduction__inform">
              At Qwqer, we offer transparent pricing. You can set your own price for orders to fit your budget.
              <br><br>
              We provide a recommended price as a guide. It increases the chance of a quick delivery. You can set it lower if you're flexible on time, or higher for immediate pickup and extra services like document signing.
            </p>
          </div> 
          <br>
          <center> 
            <a href="https://us.qwqer.com/login">
              <button class="introduction__btn">
                Become a client
              </button>
            </a>
          </center>
        </div>
      </div>

      <div class="introduction__desktop">
        <img class="introduction__img" src="../assets/img/boxes-desktop.png" />
        <div class="introduction__rule">
          <p class="introduction__rule-text">Power Up <br> your order with a <br>$10  gift card code<br>SAVE10</p>
        </div>
      </div>
    </div>
    <img
      class="introduction__line introduction__line-one"
      src="../assets/img/lines/line-introduction-1.svg"
    />
    <img
      class="introduction__line introduction__line-two"
      src="../assets/img/lines/line-introduction-2.svg"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { formStore } from "@/store/form.store";
import AddressInput from "@/components/Inputs/AddressInput.vue";

const { form, hasArrivalPoint,price } = storeToRefs(formStore());
const { routeCalcHandler } = formStore();

const disabled = computed(() => {
  return !hasArrivalPoint.value || !form.value.route.departure_point.address;
});

function buttonHandler() {
  calculateHandler();

  const element = document.querySelector("#calculator");
  let top = element.offsetTop;

  window.scrollTo(0, top);
}
const calculateHandler = () => {
  if (process.env.NODE_ENV === "production")
    dataLayer.push({
      event: "calculate",
      eventCategory: "btn_click",
      eventAction: "calculate_cost",
    });
};
const formStart1Handler = () => {
  if (process.env.NODE_ENV === "production")
    dataLayer.push({
      event: "form_start_1",
      eventCategory: "form_action",
      eventAction: "form_start_1",
    });
};
</script>

<style lang="scss" scoped>
.introduction {
  background: #fbfbfb;
  padding-bottom: 40px;

  background-image: url("@/assets/img/mobile-line-introduction.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px 80px;

  &__line {
    display: none;
  }

  &__desktop {
    display: none;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    padding-top: 28px;
  }

  &__container {
    padding: 0px 16px;
  }

  &__img {
    width: 201px;
    height: 216px;
  }

  &__rule {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #37352f;
    position: absolute;
    top: -8px;
    right: -36px;

    &-text {
      font-family: "PollyRoundedRegular", "Open Sans";
      font-size: 9px;
      font-weight: 400;
      line-height: 12px;
      text-align: center;
      max-width: 68px;

      color: #fff;
    }
  }

  &__title {
    padding-top: 11px;

    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 40px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;

    color: #18191d;

    span {
      color: #112e95;
    }
  }

  &__inform {
    padding-top: 25px;
    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    color: #18191d;

    span {
      color: #112e95;
    }
  }

  &__description {
    padding-top: 16px;
    font-family: "PollyRoundedThin", "Open Sans";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-align: left;
  }

  &__form {
    margin: 24px 16px 0 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    min-height: 212px;
    border-radius: 16px;

    box-shadow: 0px 12px 28px 5px rgba(15, 15, 15, 0.15);
  }

  &__btn {
    margin-top: auto;
    padding: 19px 68px;

    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;

    border: none;
    border-radius: 30px;
    background: #112e95;

    color: #fff;

    cursor: pointer;

    &:hover {
      background: #445eb8;
    }

    &:active {
      opacity: 1;
      background: #0c247d;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 88px;
    padding-left: 13px;
    padding-right: 13px;
    background-image: initial;
    position: relative;

    &__wrapper {
      display: none;
    }

    &__container {
      padding: 0;

      &-desktop {
        max-width: 680px;
        z-index: 10;
      }
    }

    &__title {
      padding-top: 65px;
      max-width: 680px;
      line-height: 48px;
    }

    &__inform {
      padding-top: 39px;
      font-size: 18px;
    }

    &__description {
      max-width: 580px;
      font-size: 18px;
    }

    &__wrapper-desktop {
      max-width: 1180px;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      display: flex;
    }

    &__form {
      margin: 0;
      padding: 40px;
      margin-top: 59px;
      max-width: 580px;
      box-sizing: border-box;
      background: #fff;
    }

    &__desktop {
      display: block;
      position: absolute;
      bottom: -67px;
      right: -136px;
    }

    &__img {
      width: 690px;
      height: auto;
    }

    &__rule {
      width: 150px;
      height: 150px;
      top: -26px;
      right: 167px;

      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        max-width: initial;
      }
    }

    &__btn {
      margin-top: 32px;
    }

    &__line {
      display: block;
      position: absolute;
      z-index: 1;

      &-one {
        bottom: 0;
        width: 25vw;
        height: auto;
      }

      &-two {
        top: 0;
        right: 0;
        width: 49vw;
        height: auto;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1400px) {
    &__line {
      &-one {
        bottom: 0;
        width: 25vw;
        height: auto;
      }

      &-two {
        top: 28%;
        right: 0;
        width: 49vw;
        height: auto;
      }
    }
  }
  }
.giftcard {
color: #112e95;

}
.giftcard {
color: #112e95;

}
</style>
