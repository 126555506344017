import axios from "axios";
import { ref } from "vue";

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const useConstants = () => {
  const maxRecipientAddresses = ref(null);

  const fetchMaxRecipientAddresses = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}bases/constants`);
      maxRecipientAddresses.value = data?.data?.ROUTE_MAX_ARRIVAL_POINTS;
    } catch (error) {
      console.error({ error });
    }
  };

  return {
    maxRecipientAddresses,
    fetchMaxRecipientAddresses,
  };
};
