<template>
  <div
    class="layout"
    :style="isMenuOpen ? 'position: fixed; width: 100%; height: 100%' : ''"
  >
    <header class="layout__header header">
      <nav class="header__nav">
        <a href="">
          <img
            class="header__logo"
            src="./assets/img/logo.svg"
            width="123"
            height="23"
            alt="logo qwqer-logo"
          />
        </a>
        <div class="header__container">
          <!-- <a class="header__link header__link-phone" href="tel:+88001234567"
            >8-800-123-45-67</a
          > -->
          <a
            @click="loginHandler"
            class="header__link header__link-login"
            href="https://us.qwqer.com/"
            >Log in</a
          >
          <a
            @click="signUpHandler"
            class="header__link header__link-signup"
            href="https://us.qwqer.com/"
            >Sign up</a
          >
        </div>
        <button class="header__btn" @click="openMenu">
          <img class="header__icon" src="./assets/img/menu.svg" />
        </button>
      </nav>
    </header>
    <Menu v-if="isMenuOpen" @closeMenu="closeMenu" />
    <main class="layout__main">
      <Introduction />
      <ShippingForm @openModal="openModal" />
      <Service />
      <WeCare />
      <!--<FirstOrder />-->
      
    </main>
    <footer class="layout__footer">
      <Footer />
      <div class="layout__footer-desktop">
        <ul class="layout__footer-list">
          <!-- <li class="layout__footer-item">
            <a class="layout__footer-link" href="" target="_blank"
              >Terms & Conditions</a
            >
          </li> -->
          <li class="layout__footer-item">
            <a
              class="layout__footer-link"
              href="https://qwqer.com/policies-agreements-page/"
              target="_blank"
              >Privacy Policy</a
            >
          </li>
          <li class="layout__footer-item">
            <a
              class="layout__footer-link"
              href="https://business.qwqer.com/faq.html"
              target="_blank"
              >FAQ</a
            >
          </li>
          <li class="layout__footer-item">2024 </li>
        </ul>
      </div>
    </footer>
    <notifications pauseOnHover style="top: 20px; right: 20px" />
    <Teleport to="html">
      <div class="fog" v-if="isOpenModal" @click.self="closeModal">
        <Modal @closeModal="closeModal" />
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Menu from "@/components/Menu.vue";
import ShippingForm from "@/components/ShippingForm.vue";
import Introduction from "@/components/Introduction.vue";
import Service from "@/components/Service.vue";
import WeCare from "@/components/WeCare.vue";
import Modal from "@/components/Modal.vue";
import Footer from "@/components/Footer.vue";

const isOpenModal = ref(false);
const isMenuOpen = ref(false);

function openModal() {
  isOpenModal.value = true;
}

function closeModal() {
  isOpenModal.value = false;
}

function closeMenu() {
  isMenuOpen.value = false;
}

function openMenu() {
  isMenuOpen.value = true;
}

const loginHandler = () => {
  if (process.env.NODE_ENV === "production")
    dataLayer.push({
      event: "log_in_1",
      eventCategory: "btn_click",
      eventAction: "enter",
    });
};
const signUpHandler = () => {
  if (process.env.NODE_ENV === "production")
    dataLayer.push({
      event: "sign_up_1",
      eventCategory: "btn_click",
      eventAction: "enter",
    });
};

window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "veavwwko",
};
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/veavwwko";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();
</script>

<style lang="scss" scoped>
.fog {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px) brightness(0.5);
}

.layout {
  &__header {
    padding: 12px 16px;
  }

  &__main {
  }

  &__footer {
    min-height: 415px;
    background: #18191d;

    &-desktop {
      display: none;
    }
  }

  @media (min-width: 768px) {
    &__header {
      padding: 13px;
    }

    &__footer {
      min-height: inherit;
      &-desktop {
        display: block;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
      }

      &-list {
        max-width: 1180px;
        margin: 0 auto;
        display: flex;
        padding: 20px 0;
      }

      &-item {
        font-family: "PollyRoundedThin", "Open Sans";
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;

        color: rgba(255, 255, 255, 1);

        margin-left: 70px;

        &:first-child {
          margin: 0;
        }

        &:last-child {
          margin-left: auto;
        }
      }

      &-link {
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.header {
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    padding: 0;
    border: none;
    background: none;
  }

  &__icon {
    padding: 4px;
  }

  &__container {
    display: none;
  }

  @media (min-width: 768px) {
    max-width: 1180px;
    margin: 0 auto;
    &__logo {
      width: 157px;
      height: 29px;
    }

    &__btn {
      display: none;
    }

    &__container {
      display: flex;
    }

    &__link {
      display: flex;
      align-items: center;
      text-align: left;

      text-decoration: none;

      color: #18191d;

      &-phone {
        margin-right: 101px;
        font-family: "PollyRoundedThin", "Open Sans";
        font-size: 18px;
        font-weight: 300;
        line-height: 22px;

        color: #18191d;
      }

      &-login {
        margin-right: 44px;
        font-family: "PollyRoundedRegular", "Open Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;

        color: #18191d;
      }

      &-signup {
        padding: 12px 24px;
        background: #112e95;
        border-radius: 30px;

        font-family: "PollyRoundedRegular", "Open Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;

        color: #fff;

        &:active {
          opacity: 1;
          background: #0c247d;
        }
      }
    }
  }
}
</style>
