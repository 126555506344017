import { ref, watch, computed } from "vue";
import { useNotification } from "@kyvg/vue3-notification";
import _ from "lodash";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_BASE_URL;

export const useForm = () => {
  const initialForm = {
    cargo_type: "documents",
    title: "",
    route: {
      asap: false,
      departure_time: null,
      departure_point: {
        point: {
          lat: 0,
          lon: 0,
        },
        address: "",
        phone: "",
      },
      arrival_points: [
        {
          point: {
            lat: 0,
            lon: 0,
          },
          address: "",
          phone: "",
        },
      ],
    },
  };

  const { notify } = useNotification();
  const price = ref(0);
  const form = ref(_.cloneDeep(initialForm));

  const hasArrivalPoint = computed(() => {
    return form.value.route.arrival_points.every((point) => point.address);
  });

  watch(
    () => form.value.route.departure_point.address,
    (value) => {
      if (value && hasArrivalPoint.value) {
        console.log(123);
        calcRoute();
      }
    },
    { deep: true }
  );

  const calcRoute = async () => {
    try {
      const { data } = await axios.post(`${BASE_URL}bases/orders/calc_route`, {
        ...form.value.route,
        cargo_type: form.value.cargo_type || "documents",
        car_type: "sedan",
      });
      price.value = data.data.price / 100 || 0;
      return data;
    } catch (error) {
      notify({
        text: error.response?.data?.error?.error_msg,
        type: "error",
      });
    }
  };

  function routeCalcHandler() {
    if (hasArrivalPoint.value && form.value.route.departure_point.address) {
      calcRoute();
    }
  }

  const sendForm = async () => {
    try {
      const result = await axios.post(`${BASE_URL}landings/b2c/order`, {
        ...form.value,
        route: {
          ...form.value.route,
          arrival_points: form.value.route.arrival_points.map((point) => ({
            ...point,
            phone: `1${point.phone}`,
          })),
          departure_point: {
            ...form.value.route.departure_point,
            phone: `1${form.value.route.departure_point.phone}`,
          },
        },
      });
      price.value = 0;
      form.value = _.cloneDeep(initialForm);
      return result;
    } catch (error) {
      throw (
        error.response?.data?.error?.error_msg ?? "Contact the administrator."
      );
    }
  };

  return {
    form,
    price,
    hasArrivalPoint,
    routeCalcHandler,

    sendForm,
  };
};
