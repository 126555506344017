<template>
  <label
    @click="openModal"
    :class="`address__label address__label-introduction`"
  >
    <div
      class="address__input"
      :class="{
        'address__input--error': error,
      }"
    >
      {{ arrival?.address || placeholder }}
    </div>
    <img class="address__geo" src="@/assets/img/geo.png" />
  </label>

  <teleport to="html">
    <div class="fog" v-if="isOpenModal" @click.self="closeModal">
      <ModalAddressAdd
        :data="{ arrival }"
        @close="closeModal"
        @changeAction="onChange"
      />
    </div>
  </teleport>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import ModalAddressAdd from "@/components/Modals/ModalAddressAdd.vue";

const emit = defineEmits(["setAddress", "click"]);
defineProps({
  arrival: {
    type: Object,
    default: () => {},
  },
  placeholder: {
    type: String,
    default: "Sender address",
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const isOpenModal = ref(false);

watch(isOpenModal, (v) => {
  if (v) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
});

function openModal() {
  emit("click");
  isOpenModal.value = true;
}
function closeModal() {
  isOpenModal.value = false;
}
const onChange = (params) => {
  emit("setAddress", params);
};
</script>

<style lang="scss" scoped>
.address {
  &__input {
    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border: none;
    outline: none;

    color: #6e6d69;

    width: 100%;

    padding: 15px 50px 15px 17px;
    border: 1.5px solid #ebebea;
    border-radius: 12px;

    &:focus {
      border-color: #18191d;
    }
    &--error {
      border-color: red;
    }
  }

  &__geo {
    position: absolute;
    top: 16px;
    right: 19px;
    width: 19px;
    height: 21.5px;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;

    &:not(:first-child) {
      margin-top: 13px;
    }

    &-introduction:not(:first-child) {
      @media (min-width: 768px) {
        margin-top: 20px;
      }
    }
  }
}

.fog {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px) brightness(0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
</style>
