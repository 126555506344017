<template>
  <!--<div class="shipping" id="calculator">
   <h2 class="shipping__title">Calculate your personal shipping cost</h2>
    <form class="shipping__form">
      <div class="shipping__container">
        <h3 class="shipping__title-item">Select the best delivery time</h3>
        <ul class="shipping__list">
          <li
            class="shipping__item shipping__item-delivery"
            v-for="delivery in deliveryTimes"
            :key="delivery.id"
          >
            <input
              class="shipping__input-radio"
              type="radio"
              :value="delivery.value"
              v-model="times"
              name="deliveryTime"
              :id="delivery.title"
            />
            <label class="shipping__label" :for="delivery.title">
              <div
                class="shipping__circle"
                :style="`background: ${delivery.imgColor}`"
              >
                <img
                  class="shipping__img"
                  :src="`../img/cards/${delivery.img}.svg`"
                />
              </div>
              <h4 class="shipping__title-card">{{ delivery.title }}</h4>
              <p class="shipping__text">{{ delivery.text }}</p>
              -->
              <!-- <p class="shipping__description shipping__description-delivery">
                from {{ delivery.price }} $
              </p> -->
 <!--           </label>
          </li>
        </ul>
      </div>
      <div class="shipping__container">
        <h3 class="shipping__title-item">Select your parcel weight</h3>
        <ul class="shipping__list">
          <li
            class="shipping__item shipping__item-weight"
            v-for="weight in deliveryWeights"
            :key="weight.id"
          >
            <input
              class="shipping__input-radio"
              type="radio"
              :value="weight.value"
              v-model="form.cargo_type"
              name="deliveryWeight"
              :id="weight.title"
            />
            <label
              class="shipping__label shipping__label-desktop"
              :for="weight.title"
            >
              <div
                class="shipping__circle"
                :style="`background: ${weight.imgColor}`"
              >
                <img
                  class="shipping__img"
                  :src="`../img/cards/${weight.img}.svg`"
                />
              </div>
              <h4 class="shipping__title-card">{{ weight.title }}</h4>
              <p class="shipping__description shipping__description-weight">
                {{ weight.description }}
              </p>
            </label>
          </li>
        </ul>
      </div>
      <div class="shipping__container">
        <h3 class="shipping__title-item">Select your desired addresses</h3>
        <ul class="shipping__list-address">
          <li class="shipping__item-address">
            <div>
              <img class="shipping__line" src="../assets/img/line.svg" />
              <h4 class="shipping__title shipping__title-address">
                {{ "Sender" }}
              </h4>
            </div>
            <div class="shipping__address">
              <AddressInput
                :arrival="form.route.departure_point"
                :error="v$?.route.departure_point.address.required.$invalid"
                placeholder="Sender address"
                @set-address="
                  (params) => {
                    form.route.departure_point.address = params.address;
                    form.route.departure_point.point.lat = params.lat;
                    form.route.departure_point.point.lon = params.lon;
                  }
                "
              />
              <InputPhone
                v-model="form.route.departure_point.phone"
                :section="`shipping`"
                placeholder="Enter contact mobile number"
                :error="
                  v$?.route.departure_point.phone.required.$invalid ||
                  v$?.route.departure_point.phone.minLength.$invalid
                "
              />
            </div>
          </li>

          <li
            class="shipping__item-address"
            v-for="(arrival, index) in form.route.arrival_points"
            :key="index"
          >
            <img
              class="shipping__line"
              :style="
                form.route.arrival_points.length === index + 1
                  ? 'display: none;'
                  : ''
              "
              src="../assets/img/line.svg"
            />
            <div
              :style="
                index > 0
                  ? 'display: flex; justify-content: space-between;'
                  : ''
              "
            >
              <h4 class="shipping__title shipping__title-address">
                {{ "Recipient" }}
              </h4>
              <button
                v-if="index > 0"
                href=""
                class="shipping__remove"
                @click.prevent="(evt) => removeAddressHandler(evt, index)"
              >
                Delete
              </button>
            </div>
            <div class="shipping__address">
              <AddressInput
                :arrival="arrival"
                :error="
                  v$.route.arrival_points.$each?.$response?.$errors[index]
                    ?.address?.length &&
                  v$.route.arrival_points?.$each?.$invalid
                "
                placeholder="Recipient address"
                @set-address="
                  (params) => {
                    arrival.address = params.address;
                    arrival.point.lat = params.lat;
                    arrival.point.lon = params.lon;
                    routeCalcHandler();
                  }
                "
              />
              <InputPhone
                v-model="arrival.phone"
                :error="
                  v$.route.arrival_points?.$each?.$response?.$errors[index]
                    ?.phone?.length && v$.route.arrival_points?.$each?.$invalid
                "
                :section="`shipping`"
                placeholder="Enter contact mobile number"
              />
            </div>
          </li>
          <div class="shipping__inform-wrapper">
            <button
              :disabled="isDisabled"
              class="shipping__add"
              @click.prevent="addAddressHandler"
            >
              <img class="shipping__add-img" src="@/assets/img/add.svg" />Add
              address
            </button>
            <p class="shipping__information" v-if="isDisabled">
              The maximum number of recipients is {{ maxDropOffPoints }}
            </p>
          </div>
        </ul>
      </div>
      <div class="shipping__container">
        <h3 class="shipping__title-item">What should we deliver?</h3>
        <div class="shipping__wrapper">
          <SelectInput
            v-model="form.title"
            :error="v$?.title.required.$invalid"
          />
        </div>
      </div>
      <div class="shipping__container">
        <h3 class="shipping__title-item">Total cost</h3>
        
<div class="introduction__form">
        <div class="shipping__wrapper">
          <h4 class="shipping__title shipping__title-total">
            {{ `Recommended price is $ ${price ? price : " "}` }}
          </h4>
          <br><br>
          <p class="shipping__description">
            At Qwqer, we offer transparent pricing. You can set your own price for orders to fit your budget. We provide a recommended price as a guide. It increases the chance of a quick delivery. You can set it lower if you're flexible on time, or higher for immediate pickup and extra services like document signing.
          </p>  
    
        </div>
      </div>
 
    -->
<!--

          <button
            class="shipping__btn"
            type="submit"
            @click.prevent="submitButtonHandler"
          >
            Publish your order
          </button>
          <p class="shipping__description shipping__description-total">
            By signing up, I agree to Qwqer's
            <a
              href="https://qwqer.com/policies-agreements-page/"
              target="_blank"
              class="shipping__link"
              >Privacy</a
            >
            and
            <a
              href="https://qwqer.com/policies-agreements-page/"
              target="_blank"
              class="shipping__link"
              >Terms</a
            >
          </p>
        </div>
      </div>
    </form>
    -->
    <div></div>
  
  
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { formStore } from "@/store/form.store";
import { useNotification } from "@kyvg/vue3-notification";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useConstants } from "@/composables/useConstants";

import InputPhone from "@/components/Inputs/InputPhone.vue";
import AddressInput from "@/components/Inputs/AddressInput.vue";
import SelectInput from "@/components/Inputs/SelectInput.vue";

const emit = defineEmits("openModal");
defineProps({
  items: { type: Object, default: () => ({}) },
});

const deliveryTimes = [
  {
    id: 1,
    img: "time",
    imgColor: "#EBEBEA",
    title: "As soon as possible",
    text: "The order will be collected and delivered by the nearest available courier.",
    value: "soon",
    price: "xxx",
  },
  {
    id: 2,
    img: "calendar",
    imgColor: "#EBEBEA",
    title: "Schedule",
    text: "The courier will arrive at the address(es) at any time that’s convenient for you",
    value: "schedule",
    price: "xxx",
  },
];
const deliveryWeights = [
  {
    id: 1,
    img: "envelope",
    imgColor: "#D6E4EE",
    title: "Envelope",
    description: "Suitable for letters, cards and documents. ",
    value: "documents",
  },
  {
    id: 2,
    img: "smallParcel",
    imgColor: "#E6DEED",
    title: "Small Parcel",
    description: "Suitable for cakes, shoeboxes and soccer balls.",
    value: "parcel",
  },
  {
    id: 3,
    img: "mediumParcel",
    imgColor: "#FAEDCC",
    title: "Medium Parcel",
    description: "Suitable for guitars, printers and coffee makers.",
    value: "small_size",
  },
  {
    id: 4,
    img: "largeParcel",
    imgColor: "#DEECDC",
    title: "Large Parcel",
    description: "Suitable for luggage, bicycles and dinner tables. ",
    value: "average_size",
  },
];

const { notify } = useNotification();
const { form, price } = storeToRefs(formStore());
const { sendForm, routeCalcHandler } = formStore();
const { maxRecipientAddresses, fetchMaxRecipientAddresses } = useConstants();
const times = ref("");
const rules = computed(() => {
  return {
    title: {
      required,
      $lazy: true,
    },
    route: {
      departure_point: {
        address: {
          required,
          $lazy: true,
        },
        phone: {
          required,
          minLength: minLength(10),
          $lazy: true,
        },
      },
      arrival_points: {
        $each: helpers.forEach({
          address: {
            required,
          },
          phone: {
            required,
            minLength: minLength(10),
          },
        }),
      },
    },
  };
});

const v$ = useVuelidate(rules, form, { $lazy: true });

const maxDropOffPoints = computed(() => {
  return maxRecipientAddresses.value ?? 5
});

const isDisabled = computed(() => {
  return (
    form.value.route.arrival_points.length >= maxDropOffPoints.value
  );
});

watch(times, (v) => {
  if (v === "soon") {
    form.value.route.asap = true;
  } else {
    form.value.route.asap = false;
  }
});

function addAddressHandler() {
  if (isDisabled.value) return;
  form.value.route.arrival_points.push({
    point: {
      lat: 0,
      lon: 0,
    },
    address: "",
    phone: "",
  });
}
function removeAddressHandler(evt, index) {
  form.value.route.arrival_points?.splice(index, 1);
  routeCalcHandler();
}

const formSubmitHandler = () => {
  if (process.env.NODE_ENV === "production")
    dataLayer.push({
      event: "form_submit",
      eventCategory: "form_action",
      eventAction: "place_order",
    });
};
async function submitButtonHandler() {
  const result = await v$.value.$validate();
  if (!result) {
    return notify({
      text: "Fill in required fields.",
      type: "error",
    });
  }
  try {
    await sendForm();
    emit("openModal");
    formSubmitHandler();
    v$.value.$reset();
  } catch (error) {
    notify({
      text: error,
      type: "error",
    });
  }
}

onMounted(() => fetchMaxRecipientAddresses());
</script>

<style lang="scss" scoped>
.shipping {
  padding: 0px 0;
  background: rgba(32, 167, 165, 0.1);

  &__information {
    margin-top: 8px;
    font-family: "PollyRoundedThin", "Open Sans";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;

    color: #6e6d69;
  }

  &__text {
    display: none;
  }

  &__wrapper {
    background: #fff;
    border-radius: 16px;
    padding: 16px;
    margin-top: 2px;
  }

  &__link {
    text-decoration: none;
    font-family: "PollyRoundedBold", "Open Sans";
    color: #18191d;

    &:hover {
      opacity: 0.5;
    }
  }

  &__btn {
    margin-top: 19px;
    padding: 19px;
    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    border: none;
    border-radius: 30px;
    background: #112e95;
    width: 100%;
    color: #fff;

    cursor: pointer;

    &:hover {
      background: #445eb8;
    }

    &:active {
      opacity: 1;
      background: #0c247d;
    }
  }

  &__title {
    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    text-align: center;

    color: #18191d;

    &-item {
      padding-left: 4vw;

      font-family: "PollyRoundedThin", "Open Sans";
      font-size: 18px;
      font-weight: 300;
      line-height: 32px;
      text-align: left;
      letter-spacing: 0.2px;

      color: #18191d;
    }

    &-card {
      margin-top: 16px;

      font-family: "PollyRoundedRegular", "Open Sans";
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;

      color: #18191d;
    }

    &-address {
      font-size: 18px;
      text-align: left;
      line-height: 22px;
      margin-bottom: 12px;
    }

    &-total {
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }
  }

  &__description {
    font-family: "PollyRoundedThin", "Open Sans";
    font-weight: 600;
    text-align: left;

    &-delivery {
      margin-top: auto;

      font-size: 28px;

      line-height: 31px;
    }

    &-weight {
      margin-top: 12px;

      font-size: 16px;

      line-height: 22px;
    }

    &-total {
      margin-top: 12px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__container {
    margin-top: 0px;

    &:not(:first-child) {
      margin-top: 3px;
    }
  }

  &__list {
    background: #fff;
    border-radius: 16px;
    display: flex;
    padding: 16px;
    margin-top: 8px;
    overflow: auto;
    gap: 16px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &-address {
      margin-top: 8px;
      padding: 17px 16px 18px 16px;
      background: #fff;
      border-radius: 16px;
    }
  }

  &__item {
    &-delivery {
      min-width: 212px;
      min-height: 193px;
    }

    &-weight {
      min-width: 216px;
      min-height: 177px;
    }

    &-address {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1.5px solid #f5f5f5;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 15px;

    cursor: pointer;

    &:hover {
      border: 1.5px solid #cccdd7;
    }
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__img {
    filter: brightness(0%);
  }

  &__line {
    display: none;
  }

  &__input {
    &-radio {
      display: none;

      &:checked + .shipping__label {
        border-color: #112e95;

        .shipping__img {
          filter: brightness(100%);
        }

        .shipping__circle {
          background: #112e95 !important;
        }
      }
    }
  }

  &__add,
  &__remove {
    padding: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-family: "PollyRoundedRegular", "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    border: none;
    background: initial;

    text-decoration: none;

    color: #112e95;

    cursor: pointer;

    &-img {
      margin-right: 10px;
    }

    &:disabled {
      opacity: 0.4;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      color: #0c247d;
    }
  }

  &__remove {
    margin: 0;
  }

  @media (min-width: 768px) {
    padding-top: 95px;
    padding-bottom: 115px;

    &__inform-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__information {
      font-size: 16px;
      line-height: 20px;
    }

    &__title {
      font-size: 64px;
      line-height: 68px;

      &-item {
        padding: 0;
        font-size: 28px;
        line-height: 32px;
      }

      &-card {
        font-size: 27px;
        line-height: 32px;
      }

      &-address {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
      }

      &-total {
        font-size: 32px;
        line-height: 32px;
      }
    }

    &__form {
      max-width: 1180px;
      margin: 0 auto;
    }

    &__container {
      margin-top: 0px;

      &:not(:first-child) {
        margin-top: 0px;
      }
    }

    &__list {
      margin-top: 20px;
      padding: 40px;
      flex-wrap: wrap;
      justify-content: center;

      &-address {
        margin-top: 20px;
        padding: 40px;
      }
    }

    &__item {
      &-address {
        padding-left: 42px;
        position: relative;

        &:first-child {
          &:after {
            border-color: #ad008c;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 4px solid #112e95;
        }

        &:not(:last-child) {
          padding-bottom: 25px;
        }

        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }

    &__circle {
      width: 60px;
      height: 60px;
    }

    &__img {
      width: 32px;
      height: 32px;
    }

    &__label {
      padding: 24px;
      max-width: 540px;
      box-sizing: border-box;

      &-desktop {
        height: 224px;
        width: 261px;
      }
    }

    &__text {
      display: block;

      max-width: 492px;

      margin-top: 16px;

      font-family: "PollyRoundedThin", "Open Sans";
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    &__description {
      &-delivery {
        margin-top: 20px;
        font-size: 32px;
        line-height: 42px;
      }

      &-weight {
        margin-top: 20px;
      }

      &-total {
        margin-top: 20px;
        letter-spacing: -1.3px;
      }
    }

    &__address {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
      gap: 20px;
      justify-content: space-between;
    }

    &__add {
      padding-left: 42px;
      margin-top: 5px;
    }

    &__line {
      display: block;
      position: absolute;
      left: 5px;
      bottom: 5px;
    }

    &__wrapper {
      margin-top: 23px;
      padding: 40px;
    }

    &__btn {
      margin-top: 30px;
      max-width: 272px;
    }
  }
}
</style>
