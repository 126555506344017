<template>
  <div class="map">
    <div ref="mapDiv" class="map__map"></div>
    <svg
      class="modal__close"
      @click="() => $emit('close')"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.47162 1.46978C1.76452 1.17689 2.23939 1.17689 2.53228 1.46978L16.5313 15.4688C16.8242 15.7617 16.8242 16.2366 16.5313 16.5295C16.2384 16.8224 15.7636 16.8224 15.4707 16.5295L1.47162 2.53044C1.17873 2.23755 1.17873 1.76268 1.47162 1.46978Z"
        fill="#000000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5303 1.46978C16.8232 1.76268 16.8232 2.23755 16.5303 2.53044L2.53127 16.5295C2.23838 16.8224 1.7635 16.8224 1.47061 16.5295C1.17772 16.2366 1.17772 15.7617 1.47061 15.4688L15.4697 1.46978C15.7626 1.17689 16.2374 1.17689 16.5303 1.46978Z"
        fill="#000000"
      />
    </svg>
    <div v-show="!loading" class="map__address">
      <input id="autocomplete" ref="autocomplete" v-model="address" />
      <!-- <button
        class="button button--primary"
        @click="search">
        Search
      </button> -->
      <button
        v-if="selectedPosition.lat || selectedPosition.lng"
        :disabled="!addressValidation"
        class="button button--primary"
        @click="selectAddress"
      >
        Set selected address
      </button>
    </div>
    <div v-if="loading" class="map__loading">
      <easy-spinner />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { defineComponent, computed, ref, watch, onBeforeUnmount } from "vue";

import { useGeolocation } from "@/composables/useGeolocation";
import { Loader } from "@googlemaps/js-api-loader";
import { useNotification } from "@kyvg/vue3-notification";

export default defineComponent({
  emits: ["close", "changeAction"],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const { notify } = useNotification();
    const { coords } = useGeolocation();
    const currentPosition = computed(() => ({
      lat: coords.value.latitude,
      lng: coords.value.longitude,
    }));
    const selectedPosition = ref({ lat: 0, lng: 0 });
    // eslint-disable-next-line no-unused-vars
    let clickListener;
    let geocoder;
    let address = ref(null);
    let loading = ref(true);
    const marker = ref(null);
    const mapDiv = ref(null);
    const geocoderAddressResult = ref("");
    let map = ref(null);

    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      libraries: ["places"],
    });

    const geocode = (request) => {
      geocoder
        .geocode(request)
        .then((result) => {
          const { results } = result;
          address.value = results[0].formatted_address;
          geocoderAddressResult.value = results[0].formatted_address;
          selectedPosition.value.lat = results[0].geometry.location.lat();
          selectedPosition.value.lng = results[0].geometry.location.lng();
          map.value.setCenter({
            lat: selectedPosition.value.lat,
            lng: selectedPosition.value.lng,
          });
          marker.value.setPosition({
            lat: selectedPosition.value.lat,
            lng: selectedPosition.value.lng,
          });
          marker.value.setMap(map.value);

          return results;
        })
        .catch((e) => {
          notify({
            text: "Address not found.",
            type: "warn",
          });
          console.log(e);
        });
    };

    const reverseGeocode = (lat, lng) => {
      var latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode(
        {
          latLng: latlng,
        },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              address.value = results[1].formatted_address;
              geocoderAddressResult.value = results[1].formatted_address;
              marker.value.setPosition(results[0].geometry.location);
              marker.value.setMap(map.value);
            } else {
              notify({
                text: "Address not found. Choose another location.",
                type: "warn",
              });
            }
          } else {
            notify({
              text: "Address not found.",
              type: "warn",
            });
          }
        }
      );
    };

    const addressValidation = computed(() => {
      return address.value === geocoderAddressResult.value;
    });

    watch(coords, async () => {
      await loader.load();

      geocoder = new google.maps.Geocoder();
      map.value = new google.maps.Map(mapDiv.value, {
        center: currentPosition.value,
        zoom: coords.value.zoom || 12,
        disableDefaultUI: true,
      });
      marker.value = new google.maps.Marker({
        map,
      });
      clickListener = map.value.addListener(
        "click",
        ({ latLng: { lat, lng } }) => {
          selectedPosition.value = { lat: lat(), lng: lng() };
          reverseGeocode(
            selectedPosition.value.lat,
            selectedPosition.value.lng
          );
        }
      );

      setLastPosition();
      loading.value = false;

      if (document.getElementById("autocomplete")) {
        const ac = new window.google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          {
            fields: ["formatted_address", "geometry"],
            types: ["premise", "subpremise", "street_address"],
          }
        );

        ac.bindTo("bounds", map.value);

        ac.addListener("place_changed", () => {
          let place = ac.getPlace();
          address.value = place.formatted_address;
          geocoderAddressResult.value = place.formatted_address;
          selectedPosition.value.lat = place.geometry.location.lat();
          selectedPosition.value.lng = place.geometry.location.lng();
          map.value.setCenter({
            lat: selectedPosition.value.lat,
            lng: selectedPosition.value.lng,
          });

          var bounds = new google.maps.LatLngBounds();
          bounds.extend({
            lat: selectedPosition.value.lat + 0.0005,
            lng: selectedPosition.value.lng + 0.0005,
          });
          bounds.extend({
            lat: selectedPosition.value.lat - 0.0005,
            lng: selectedPosition.value.lng - 0.0005,
          });
          map.value.fitBounds(bounds);

          marker.value.setPosition({
            lat: selectedPosition.value.lat,
            lng: selectedPosition.value.lng,
          });
          marker.value.setMap(map.value);
        });
      }
    });

    const search = () => {
      geocode({ address: address.value });
    };

    const selectAddress = () => {
      if (address.value && selectedPosition.value) {
        geocode({ address: address.value });
      }

      if (selectedPosition.value) {
        emit("changeAction", {
          address: address.value,
          lat: selectedPosition.value.lat,
          lon: selectedPosition.value.lng,
        });
      }

      emit("close");
    };

    function setLastPosition() {
      const lat = props.data?.arrival?.point?.lat;
      const lng = props.data?.arrival?.point?.lon;
      const addressLast = props.data?.arrival?.address;

      if (addressLast && lat && lng) {
        address.value = addressLast;
        map.value.setCenter({ lat, lng });
        marker.value.setPosition({ lat, lng });
        marker.value.setMap(map.value);
      }
    }

    onBeforeUnmount(() => {
      google.maps.event.clearInstanceListeners(
        document.getElementById("autocomplete")
      );
      const acInstance = document.querySelector(".pac-container");
      if (acInstance) {
        acInstance.parentNode.removeChild(acInstance);
      }
    });

    return {
      currentPosition,
      mapDiv,
      selectedPosition,
      address,
      search,
      selectAddress,
      loading,
      addressValidation,
    };
  },
});
</script>

<style lang="scss" scoped>
.map {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 900px;
  height: 800px;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
		border-radius: 0;
  }

  &__loading {
    position: absolute;
    top: 0;
    z-index: 10;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__map {
    width: inherit;
    height: inherit;
  }

  &__address {
    position: absolute;
    top: 30px;
    left: 20px;
    border-radius: 8px;
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 80%;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }

    input {
      border: none;
      border-bottom: 1px solid #c3c2c1;
      padding: 10px;
      /* width: 500px; */
      background: white;
      width: 100%;
    }

    .button {
      padding: 8px 20px;
      font-family: "PollyRoundedRegular", "Open Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      border: none;
      border-radius: 30px;
      background: #112e95;
      color: #fff;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: #445eb8;
      }

      &:active {
        opacity: 1;
        background: #0c247d;
      }
    }
  }

  .modal__close {
    position: absolute;
    right: 10px;
    top: 10px;
    @media (min-width: 768px) {
      display: none;
    }
  }
}
</style>
