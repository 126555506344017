import { createApp } from "vue";
import { createPinia } from "pinia";
import "@/assets/styles.css";
import Notifications from '@kyvg/vue3-notification'
import easySpinner from 'vue-easy-spinner'

import App from "./App.vue";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(easySpinner, {
  prefix: 'easy',
})
app.use(Notifications)
app.mount("#app");
